import { Observer } from 'mobx-react-lite';
import React from 'react';
import ContactForm from '../../components/ContactForm/ContactForm';
import CTAButton from '../../components/CTAButton/CTAButton';
import DefaultPageHeader from '../../components/DefaultPageHeader/DefaultPageHeader';
import FeaturedEntriesGrid from '../../components/FeaturedEntriesGrid/FeaturedEntriesGrid';
import FeaturedProject from '../../components/FeaturedProject/FeaturedProject';
import IDPAnimationFeaturedProject from '../../components/FeaturedProject/IDPAnimationFeaturedProject';
import AnimationFeatured from '../../components/FeaturedProject/AnimationFeatured';
import GridRow from '../../components/GridRow/GridRow';
import GridRowEndColumn from '../../components/GridRow/GridRowEndColumn';
import Line from '../../components/Line/Line';
import NativeVideoWrapper from '../../components/NativeVideoWrapper/NativeVideoWrapper';
import PageSection from '../../components/PageSection/PageSection';
import PageTemplate from '../../components/PageTemplate/PageTemplate';
import ProjectArchive from '../../components/ProjectArchive/ProjectArchive';
import SevicePage from '../../components/ProjectPage/ProjectPage';
import SimpleEntryGallery from '../../components/SimpleEntryGallery/SimpleEntryGallery';
import Spacer from '../../components/Spacer/Spacer';
import StandardTextBlock from '../../components/StandardTextBlock/StandardTextBlock';
import TricolorMorphDef from '../../components/TricolorMorphDef/TricolorMorphDef';
import Widowless from '../../components/Widowless/Widowless';
import { Category } from '../../constants/categories.constants';
import { useFIAInfo } from '../../content/clients/fia.client';
import './animation.scss';
// import './ProjectPageMajesticIreland.scss';
import videoWebmHD from '../../content/images/majestic-ireland/majestic-ireland-homepage-loop-hd.webm';
import videoMp4 from '../../content/images/majestic-ireland/majestic-ireland-homepage-loop.mp4';
import videoPoster from '../../content/images/majestic-ireland/majestic-ireland-screenshot-homepage-desktop-video-poster.jpg';
import { useAAAIDPAnimationInfo } from '../../content/projects/aaa-idp-animation.project';
import { useCarnetDePassagesInfo } from '../../content/projects/carnet-de-passages.project';
import { useCPDAnimationInfo } from '../../content/projects/cpd-animation.project';
import { useGCNInfo } from '../../content/projects/gcn.project';
import { useGymCoachInfo } from '../../content/projects/gymcoach.project';
import { useHopOnHopOffInfo } from '../../content/projects/hoponhopoff.project';
import { useJimOCallaghanInfo } from '../../content/projects/jim-ocallaghan.project';
import { useKLHInfo } from '../../content/projects/klh.project';
import { useMajesticIrelandInfo } from '../../content/projects/majestic-ireland.project';
import { useTBMAnimationInfo } from '../../content/projects/tbm-animation.project';
import { useUrgentAnimationInfo } from '../../content/projects/urgent-animation.project';
import { useAppContext } from '../../controllers/app.controller';
import { PageComponent } from '../../types/gatsby.types';
import { isIE } from '../../utils/browsers.utils';
import { ColorPalette } from '../../utils/colors.utils';
import { useAlexanderBoydDisplaysInfo } from '../../content/projects/alexander-boyd-displays.project';

const ServicePageAnimation: PageComponent = props => {
  const { UI } = useAppContext();

  // const fiaInfo = useFIAInfo();
  const majesticIrelandInfo = useMajesticIrelandInfo();
  // const gcnInfo = useGCNInfo();
  // const jimInfo = useJimOCallaghanInfo();
  // const klhInfo = useKLHInfo();
  const idpAnimationInfo = useAAAIDPAnimationInfo();
  // const hoponhopoffInfo = useHopOnHopOffInfo();
  // const gymcoachInfo = useGymCoachInfo();
  const cpdAnimation = useCPDAnimationInfo();
  const tbmAnimation = useTBMAnimationInfo();
  const urgentAnimation = useUrgentAnimationInfo();
  const alexanderBoydAnimation = useAlexanderBoydDisplaysInfo();

  const featuredEntries = [
    // fiaInfo,
    // majesticIrelandInfo,
    // gcnInfo,
    // hoponhopoffInfo,
    idpAnimationInfo,
    cpdAnimation,
    tbmAnimation,
    urgentAnimation,
    alexanderBoydAnimation,
    // jimInfo,
    // klhInfo,
    // gymcoachInfo,
  ];

  return <Observer children={() => (
    <PageTemplate
    {...props}
    className="PageService"
    title="Explainer Videos and Animation"
    description="Learn about the Explainer Videos and Animation services provided by AxonDivision."
    backgroundColor={ColorPalette.ivory}
    foregroundColor={ColorPalette.black}
    >

      <TricolorMorphDef
        mobile={{
          R: 'M325.737 112L393 181.629L299 205L325.737 112',
          G: 'M-31.567 76.75L22.5335 170.46L-5.48296 65.9295L-4.51704 65.6706L23.5 170.203V62H24.5V170.203L52.517 65.6706L53.483 65.9295L25.4665 170.46L79.567 76.75L80.433 77.25L26.3316 170.961L102.846 94.4465L103.554 95.1536L27.039 171.668L120.75 117.567L121.25 118.433L27.5402 172.534L132.071 144.517L132.329 145.483L27.7969 173.5H136V174.5H27.7969L132.329 202.517L132.071 203.483L27.5402 175.466L121.25 229.567L120.75 230.433L27.0388 176.332L103.554 252.846L102.846 253.554L26.3317 177.039L80.433 270.75L79.567 271.25L25.4665 177.54L53.483 282.071L52.517 282.329L24.5 177.797V286H23.5V177.797L-4.51704 282.329L-5.48296 282.071L22.5335 177.54L-31.567 271.25L-32.433 270.75L21.6682 177.039L-54.8464 253.554L-55.5535 252.846L20.9614 176.332L-72.75 230.433L-73.25 229.567L20.4597 175.467L-84.0705 203.483L-84.3294 202.517L20.2032 174.5H-88V173.5H20.2031L-84.3294 145.483L-84.0705 144.517L20.4597 172.533L-73.25 118.433L-72.75 117.567L20.9612 171.668L-55.5535 95.1536L-54.8464 94.4465L21.6683 170.961L-32.433 77.25L-31.567 76.75Z',
          B: 'M324 459.5C324 490.152 299.152 515 268.5 515C237.848 515 213 490.152 213 459.5C213 428.848 237.848 404 268.5 404C299.152 404 324 428.848 324 459.5Z',
        }}
        desktop={{
          R: "M878.407 93.0094L983.019 201.425L836.823 237.814L878.407 93.0094Z",
          G: "M-10.567 329.75L43.5335 423.46L15.517 318.929L16.483 318.671L44.5 423.203V315H45.5V423.203L73.517 318.671L74.483 318.929L46.4665 423.46L100.567 329.75L101.433 330.25L47.3316 423.961L123.846 347.446L124.554 348.154L48.039 424.668L141.75 370.567L142.25 371.433L48.5402 425.534L153.071 397.517L153.329 398.483L48.7969 426.5H157V427.5H48.7969L153.329 455.517L153.071 456.483L48.5402 428.466L142.25 482.567L141.75 483.433L48.0388 429.332L124.554 505.846L123.846 506.554L47.3317 430.039L101.433 523.75L100.567 524.25L46.4665 430.54L74.483 535.071L73.517 535.329L45.5 430.797V539H44.5V430.797L16.483 535.329L15.517 535.071L43.5335 430.54L-10.567 524.25L-11.433 523.75L42.6682 430.039L-33.8464 506.554L-34.5535 505.846L41.9614 429.332L-51.75 483.433L-52.25 482.567L41.4597 428.467L-63.0705 456.483L-63.3294 455.517L41.2032 427.5H-67V426.5H41.2031L-63.3294 398.483L-63.0705 397.517L41.4597 425.533L-52.25 371.433L-51.75 370.567L41.9612 424.668L-34.5535 348.154L-33.8464 347.446L42.6683 423.961L-11.433 330.25L-10.567 329.75Z",
          B: "M1173 438.5C1173 482.407 1137.41 518 1093.5 518C1049.59 518 1014 482.407 1014 438.5C1014 394.593 1049.59 359 1093.5 359C1137.41 359 1173 394.593 1173 438.5Z",
        }}
      />

      <DefaultPageHeader
        id="ServicePageHeader"
        // className="ServicesAnimationPageHeader"
        title="Explainer Videos & Animation"
      >
        <GridRow>
          <StandardTextBlock animateTextEnter={{ delay: 200 }}>
            <Widowless>
            If you're looking for a simple logo animation, display screen & advertising banner creative, or website content in the form of an explanatory video you have come to the right place.
            </Widowless>
          </StandardTextBlock>
          
          <StandardTextBlock animateTextEnter={{ delay: 200 }}>
            <Widowless>
            Our creative team of illustrators and animators can produce what your looking for.
            Contact us to discuss your project in more detail.
            </Widowless>
          </StandardTextBlock>

          { 
            !isIE && <>
              {!UI.cssFeatures.grid && <div />}
              {!UI.cssFeatures.grid && UI.viewport.width >= 1440 && <div />}
            </>
          }

          <GridRowEndColumn>
            <CTAButton
              title="Contact Us"
              to="/contact"
              ribbons
            // gaEvent={{ event: "contact-button--hero" }}
            >Contact Us</CTAButton>
          </GridRowEndColumn>
        </GridRow>

      </DefaultPageHeader> 

      <PageSection
      // id={'ServiceAnimationPageSectionGridRow'}>
      id='ServicePageSectionGridRow' >

        {/* <IDPAnimationFeaturedProject/> */}
        <AnimationFeatured/>
        
        <Line/> 
      {/* </PageSection>
      <PageSection id="ServicePageSectionGridRow"> */}
      
        {/* <Spacer size="xl" /> */}

         <GridRow>
          <StandardTextBlock animateTextEnter={{ delay: 200 }}>
            <h3>Storyboards & Scripting</h3>
            <Line />
            <Widowless>
              We work with our clients to develop a storyboard and script, a collaborative process which can take several rounds of edits and changes. From simple logo animations to extensive explanatory videos the process follows the same steps
            </Widowless>
          </StandardTextBlock>

          <StandardTextBlock animateTextEnter={{ delay: 200 }}>
            <h3>Illustration styles & treatments</h3>
            <Line />
            <Widowless>
              We understand Brand and Brand Guidelines and the importance of consistent implementation of using Colour, Font and the creation of an Illustrative style that sits within your company's Guidelines.  
              </Widowless>
          </StandardTextBlock>

          <StandardTextBlock animateTextEnter={{ delay: 200 }}>
            {/* <h3>Let's Work <br />Together</h3> */}
            <h3>Presentations</h3>
            <Line />
            <Widowless>
              We have a lot of experience in the production of PowerPoint presentations, evolving our client Microsoft Clipart-looking presentations, by creating illustrations and graphic assets that represent the company brand. By introducing animations, transitions, illustrations and embedded videos, we deliver highly creative engaging presentations. 
            </Widowless>
          </StandardTextBlock>

          {!UI.cssFeatures.grid && UI.viewport.width >= 1440 && !isIE && <div />}

        </GridRow>

        {/* <Spacer size="pageMargin" /> */}
        {/* <Line/> */}

      </PageSection>     

      <PageSection id="HomepageFeaturedEntriesGridSection" className="HomepageFeaturedEntriesGridSection">

      <header className="DesignGalleryHeader">
        <h2>Projects</h2>
      </header>

      <FeaturedEntriesGrid entries={featuredEntries} />

      <Spacer size="pageMargin" />
      <Line/>

    </PageSection>

    <ContactForm preSelectedTopics={['design']} />

    </PageTemplate>
  )} />
}

export default ServicePageAnimation